// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/index';
import '/vendor/js/flipclock.js';

$(document).ready(function () {
  $('.clock').FlipClock(600, {
    countdown: true,
  });

  offer.getState(function (data) {
    var iso_code = offer.getCountryIsoCode();
    if (iso_code == 'US') {
      var flag = '/static/common/shared/country-flags/us.svg';
      $('.geo-country').html(iso_code.toUpperCase());
    } else if (
      iso_code === 'GB' ||
      iso_code === 'IE' ||
      iso_code === 'FR' ||
      iso_code === 'BE' ||
      iso_code === 'LU' ||
      iso_code === 'DE' ||
      iso_code === 'AT' ||
      iso_code === 'CH' ||
      iso_code === 'CY'
    ) {
      var flag = '/static/common/shared/country-flags/us.svg';
      $('.geo-country').html('US');
    } else {
      var flag = '/static/common/shared/country-flags/' + iso_code.toLowerCase() + '.png';
      $('.geo-country').html(iso_code.toUpperCase());
    }

    if (iso_code === 'IL') {
      $("input[name='firstName'], input[name='lastName'], input[name='address'], input[name='city']")
        .keyup(function (e) {
          const _this = e.currentTarget;
          _this.value = _this.value.replace(/[^a-zA-Z0-9-z/.'"№#\ \,\-]{1}/g, '');
        })
        .change(function (e) {
          const _this = e.currentTarget;
          _this.value = _this.value.replace(/[^a-zA-Z0-9-z/.'"№#\ \,\-]{1}/g, '');
        });
    }

    $('.geo-flag').attr('src', flag);
  });
});
